import { Component } from "react";
import axios from "axios";

export class EventService extends Component {
  async getToken() {
    let params = new URLSearchParams(window.location.search);
    return params.get("token");
  }

  async showMissingArticles() {
    let params = new URLSearchParams(window.location.search);
    return params.get("show");
  }

  async getEventID() {
    let params = new URLSearchParams(window.location.search);
    let tourType = params.get("tourType");

    if (tourType == "WHL01" || tourType == "RET01") {
      return "9d16637b-2814-4230-9f44-9c79912ced71";
    } else if (tourType == "WHL06" || tourType == "RET02") {
      return "889e4e9c-d14f-428a-bad6-8774b1e8b75d";
    } else if (tourType == "WHL03" || tourType == "GJ") {
      return "7e710c10-bec4-4838-bb34-c5760efc82c8";
    } else if (tourType == "EX01") {
      return "d529bba6-d660-432d-a20f-200eb3d4766b";
    } else if (tourType == "EX02") {
      return "aa84a642-487d-4951-b4b8-636c2ee106f5";
    } else if (tourType == "EX03") {
      return "836aeafd-f705-4a07-a041-2b45b39860ee";
    } else if (tourType == "EX04") {
      return "c1aac339-f2eb-4215-bafd-c8610e21b672";
    } else if (tourType == "EX05") {
      return "73b1f6c8-8bcc-4187-83f1-2841f0e81259";
    } else if (tourType == "EXGJ") {
      return "5cf08c77-3448-4e6a-b353-2e7ad3c06fd2";
    } else if (tourType == "EXGJ2") {
      return "0537bcc8-1e98-48bf-ba4f-37123eed165b";
    } else if (tourType == "EXANCORA") {
      return "59a54fe7-2f0d-42ad-97d2-7d2ccef8a06d";
    } else {
      return null;
    }
  }

  async getApiUrl() {
    if (window.location.hostname == "showroom-virtualtour.gucci.cn") {
      return (
        "https://showroomapp.gucci.cn/Prod/virtualtour?sourceImage=cn&eventID=" +
        (await this.getEventID())
      );
    } else {
      return (
        "https://showroomapp.globalbit.gucci/Prod/virtualtour?eventID=" +
        (await this.getEventID())
      );
    }
  }

  async VirtualTour() {
    return new Promise(async (resolve, reject) => {
      var sessionAvailable = await this.isSessionStorageSupported();
      if (
        sessionAvailable &&
        sessionStorage.getItem("virtualTour-" + (await this.getEventID()))
      ) {
        resolve(
          JSON.parse(
            sessionStorage.getItem("virtualTour-" + (await this.getEventID()))
          )
        );
      } else {
        axios.defaults.headers.common["Authorization"] = await this.getToken();
        let apiUrl = await this.getApiUrl();
        let eventId = await this.getEventID();

        var cacheAvailable = await this.isCacheSupported();
        var eventChanged = false;
        if (cacheAvailable) {
          var eventIdCached = await caches.match(
            window.location.origin + "/eventID"
          );
          if (
            !(await this.isValidCache("event", eventIdCached)) ||
            (await eventIdCached.json()) != eventId
          ) {
            eventChanged = true;
            await this.resetCache();
            await this.writeCache(
              "event",
              window.location.origin + "/eventID",
              eventId
            );
          }
        }

        axios
          .get(apiUrl, {})
          .then(async (response) => {
            if (response.data.errorType) {
              reject(response.data.errorType);
            } else {
              if (cacheAvailable) {
                var lastUpdateTimestampCached = await caches.match(
                  window.location.origin + "/lastUpdateTimestamp"
                );
                if (
                  !(await this.isValidCache(
                    "event",
                    lastUpdateTimestampCached
                  )) ||
                  (await lastUpdateTimestampCached.json()) <
                    parseInt(response.data.lastUpdateTimestamp)
                ) {
                  if (!eventChanged) {
                    await this.resetCache();
                    await this.writeCache(
                      "event",
                      window.location.origin + "/eventID",
                      eventId
                    );
                  }
                  await this.writeCache(
                    "event",
                    window.location.origin + "/lastUpdateTimestamp",
                    parseInt(response.data.lastUpdateTimestamp)
                  );
                }
              }

              if (sessionAvailable) {
                sessionStorage.setItem(
                  "virtualTour-" + eventId,
                  JSON.stringify(response.data)
                );
              }

              resolve(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      }
    });
  }

  async isSessionStorageSupported() {
    try {
      var storage = window.sessionStorage;
      storage.setItem("test", "test");
      storage.removeItem("test");
      return true;
    } catch (e) {
      return false;
    }
  }

  async isCacheSupported() {
    try {
      await this.writeCache("test", window.location.href, "test");
      await caches.delete("test");
      return true;
    } catch (e) {
      return false;
    }
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async extractProductsImagesLink(photostudioDisplays, displayId) {
    return new Promise((resolve) => {
      var productsImages = [];
      if (photostudioDisplays.length > 0) {
        photostudioDisplays.map((p) => {
          if (displayId === p.id) {
            p.displays.map((d, index) => {
              d.look_hotspots.map((h) => {
                productsImages.push(h.lookImageLink);
                h.products.map((p) => {
                  productsImages.push(p.imageLink);
                });
              });

              d.no_look_hotspots.map((h) => {
                h.products.map((p) => {
                  productsImages.push(p.imageLink);
                });
              });

              if (index === p.displays.length - 1) {
                resolve(productsImages);
              }
            });
          }
        });
      } else {
        resolve(productsImages);
      }
    });
  }

  async setImage(bucket, imageLink, elementId, cacheSuppurted) {
    this.getImage(bucket, imageLink, cacheSuppurted).then((image) => {
      if (elementId) var element = document.getElementById(elementId);
      if (element) element.src = image;
    });
  }

  async resetCache() {
    caches.delete("event");
    caches.delete("thumb");
    caches.delete("display");
    caches.delete("product");
    sessionStorage.clear();
  }

  async getImageSync(bucket, imageLink, cacheSuppurted) {
    if (!imageLink) return null;

    if (cacheSuppurted) {
      var imageCached = await caches.match(imageLink);

      if (await this.isValidCache(bucket, imageCached)) {
        //console.log('read by cache: ' + imageLink)
        return imageCached.json();
      } else {
        //console.log('send request API: ' + imageLink)
        axios.defaults.headers.common["Authorization"] = await this.getToken();

        try {
          var res = await axios.get(imageLink, { responseType: "blob" });

          if (res) {
            const type = res.headers.get("Content-Type");
            const blob = new Blob([res.data], { type: type });

            let base64data = await this.parseData(blob);
            this.writeCache(bucket, imageLink, base64data);
            return base64data;
          }
        } catch {}
      }
    }
  }

  async parseData(blob) {
    return new Promise((resolve, reject) => {
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(blob);

      fileReaderInstance.onloadend = () => {
        let base64data = fileReaderInstance.result.toString();
        //console.log('finisch API: ' + imageLink)
        resolve(base64data);
      };
    });
  }

  async writeCache(bucket, imageLink, data) {
    var headers = new Headers();
    headers.append("sw-fetched-on", new Date().getTime());

    caches.open(bucket).then((cache) => {
      cache.put(
        imageLink,
        new Response(JSON.stringify(data), {
          headers: headers,
        })
      );
    });
  }

  async getImage(bucket, imageLink, cacheSuppurted) {
    return new Promise((resolve, reject) => {
      if (!imageLink) reject("error");

      if (cacheSuppurted) {
        caches
          .match(imageLink)
          .then((cachedResponse) => {
            //console.log('read by cache: ' + imageLink)
            resolve(cachedResponse.json());
          })
          .catch(async (error) => {
            //console.log('send request API: ' + imageLink)
            axios.defaults.headers.common["Authorization"] =
              await this.getToken();
            axios
              .get(imageLink, { responseType: "blob" })
              .then((res) => {
                const type = res.headers.get("Content-Type");
                const blob = new Blob([res.data], { type: type });

                const fileReaderInstance = new FileReader();
                fileReaderInstance.readAsDataURL(blob);

                fileReaderInstance.onloadend = () => {
                  let base64data = fileReaderInstance.result.toString();
                  this.writeCache(bucket, imageLink, base64data);
                  //console.log('finisch API: ' + imageLink)
                  resolve(base64data);
                };
              })
              .catch(() => {
                reject("error");
              });
          });
      }
    });
  }

  async downloadImageWithoutCache(imageLink) {
    axios.defaults.headers.common["Authorization"] = await this.getToken();
    axios
      .get(imageLink, { responseType: "blob" })
      .then((res) => {
        const type = res.headers.get("Content-Type");
        const blob = new Blob([res.data], { type: type });

        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(blob);

        fileReaderInstance.onloadend = () => {
          let base64data = fileReaderInstance.result.toString();
          return base64data;
        };
      })
      .catch(() => {
        return null;
      });
  }

  async isValidCache(bucket, response) {
    if (!response) return false;
    var fetched = response.headers.get("sw-fetched-on");
    if (
      fetched &&
      bucket == "product" &&
      parseFloat(fetched) + 1000 * 60 * 60 * 6 > new Date().getTime()
    ) {
      //console.log('cache product valid')
      return true;
    } else if (
      fetched &&
      bucket != "product" &&
      parseFloat(fetched) + 1000 * 60 * 60 * 24 * 7 > new Date().getTime()
    ) {
      //console.log('cache display/event valid')
      return true;
    } else {
      //console.log('cache not valid')
      return false;
    }
  }
}
export default EventService;
